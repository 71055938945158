import {
  Grid,
  IconButton,
  makeStyles,
  Theme,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  Box,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import AddMarkerIcon from "products/AgIcons/AddMarker";
import FieldMapIcon from "products/AgIcons/FieldMap";
import FieldNamesIcon from "products/AgIcons/FieldNames";
import ScoutIcon from "products/AgIcons/ScoutIcon";
import AddBinIcon from "products/Bindapt/AddBinIcon";
import BinsIcon from "products/Bindapt/BinsIcon";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import React, { useState } from "react";
import AddFieldIcon from "products/AgIcons/AddField";
import EditIcon from "products/AgIcons/Edit";
import DeleteIcon from "products/AgIcons/Delete";
import FieldListIcon from "products/AgIcons/FieldList";
import DeviceIcon from "products/Bindapt/BindaptIcon";
import { pond } from "protobuf-ts/pond";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    iconButtons: {
      background: theme.palette.background.default,
      margin: 5,
      boxShadow: "4px 4px 10px black"
    },
    list: {
      background: theme.palette.background.default,
      opacity: 0.75,
      borderRadius: 15,
      marginTop: 10,
      boxShadow: "4px 4px 10px black"
    },
    liFont: {
      fontWeight: 700,
      marginLeft: -15
    },
    listItems: {
      borderRadius: "3rem"
    }
  });
});

interface Props {
  toggleEditorMode: (
    modeName: "edit" | "drawPolygon" | "drawLine" | "drawPoint" | "none" | "delete" | undefined
  ) => void;
  //these modes are for the imported editor, could possibly be removed when new editor is made
  binMarkerDetails: boolean;
  toggleBinMarkerDetails: (detailsVisible: boolean) => void; //controls whether the details are visible on the
  toggleIsNewObject: (newObject: boolean) => void; //controls whether creating a new object, in this case bin, when clicking on the map to place a marker
  toggleMarkerType: (type: pond.ObjectType) => void;
  toggleFieldTitles: (show: boolean) => void;
  toggleObjectEditType: (type: pond.ObjectType) => void;
  toggleMeasurement: (measurement: boolean) => void;
  //because the draw control for the map actually changes the modes itself internally will need to have the object controller on the outside change its mode as well
  editorMode: "edit" | "drawPolygon" | "drawLine" | "drawPoint" | "none" | "delete" | undefined;
}

export default function AgMapTools(props: Props) {
  const classes = useStyles();
  const {
    toggleEditorMode,
    toggleBinMarkerDetails,
    binMarkerDetails,
    toggleIsNewObject,
    toggleMarkerType,
    toggleFieldTitles,
    toggleObjectEditType,
    toggleMeasurement
  } = props;
  const [markerType, setMarkerType] = useState<pond.ObjectType | undefined>();
  const [isNew, setIsNew] = useState(false);
  const [fieldControlDisplay, setFieldControlDisplay] = useState<"none" | "block">("none");
  const [binControlDisplay, setBinControlDisplay] = useState<"none" | "block">("none");
  const [scoutingControlDisplay, setScoutingControlDisplay] = useState<"none" | "block">("none");
  const [fieldControlHovered, setFieldControlHovered] = useState(false);
  const [binControlHovered, setBinControlHovered] = useState(false);
  const [scoutingControlHovered, setScoutingControlHovered] = useState(false);
  const [showFieldTitle, setShowFieldTitles] = useState(false);
  const history = useHistory();

  const goToMyFields = () => {
    history.push("/myFields");
  };

  const toggleControls = (control: "field" | "bin" | "scout") => {
    if (control === "field") {
      if (fieldControlDisplay === "none") {
        setFieldControlDisplay("block");
        setBinControlDisplay("none");
        setScoutingControlDisplay("none");
      } else {
        setFieldControlDisplay("none");
      }
    }
    if (control === "bin") {
      if (binControlDisplay === "none") {
        setBinControlDisplay("block");
        setFieldControlDisplay("none");
        setScoutingControlDisplay("none");
      } else {
        setBinControlDisplay("none");
      }
    }
    if (control === "scout") {
      if (scoutingControlDisplay === "none") {
        setScoutingControlDisplay("block");
        setFieldControlDisplay("none");
        setBinControlDisplay("none");
      } else {
        setScoutingControlDisplay("none");
      }
    }
    toggleEditorMode("none");
    toggleIsNewObject(false);
    changeMarkerType(pond.ObjectType.OBJECT_TYPE_UNKNOWN);
  };

  const changeMarkerType = (type: pond.ObjectType) => {
    setMarkerType(type);
    toggleMarkerType(type);
  };

  const changeNewStatus = (isNew: boolean) => {
    setIsNew(isNew);
    toggleIsNewObject(isNew);
  };

  return (
    <React.Fragment>
      <Grid container direction="column">
        <Grid item>
          <Grid container direction="row">
            <Grid item>
              <IconButton
                className={classes.iconButtons}
                style={
                  binControlDisplay === "block"
                    ? { background: "yellow" }
                    : binControlHovered
                    ? { background: "grey" }
                    : {}
                }
                title="Bin Controls"
                onClick={e => {
                  toggleControls("bin");
                }}
                onMouseOver={() => setBinControlHovered(true)}
                onMouseOut={() => setBinControlHovered(false)}>
                {binControlDisplay === "block" ? <BinsIcon type="dark" /> : <BinsIcon />}
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                className={classes.iconButtons}
                style={
                  fieldControlDisplay === "block"
                    ? { background: "yellow" }
                    : fieldControlHovered
                    ? { background: "grey" }
                    : {}
                }
                title="Field Controls"
                onClick={e => {
                  toggleControls("field");
                }}
                onMouseOver={() => setFieldControlHovered(true)}
                onMouseOut={() => setFieldControlHovered(false)}>
                {fieldControlDisplay === "block" ? <FieldMapIcon type="dark" /> : <FieldMapIcon />}
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                className={classes.iconButtons}
                style={
                  scoutingControlDisplay === "block"
                    ? { background: "yellow" }
                    : scoutingControlHovered
                    ? { background: "grey" }
                    : {}
                }
                title="Scouting Controls"
                onClick={e => {
                  toggleControls("scout");
                }}
                onMouseOver={() => setScoutingControlHovered(true)}
                onMouseOut={() => setScoutingControlHovered(false)}>
                {scoutingControlDisplay === "block" ? <ScoutIcon type="dark" /> : <ScoutIcon />}
              </IconButton>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item style={{ display: binControlDisplay }}>
              <List className={classes.list}>
                <ListItem
                  id="createBin"
                  button
                  onClick={e => {
                    changeNewStatus(true);
                    //setPlaceLine(false);
                    changeMarkerType(pond.ObjectType.OBJECT_TYPE_BIN);
                  }}
                  className={classes.listItems}
                  style={
                    isNew && markerType === pond.ObjectType.OBJECT_TYPE_BIN
                      ? { background: "green" }
                      : {}
                  }>
                  <ListItemIcon>
                    <AddBinIcon />
                  </ListItemIcon>
                  <Typography className={classes.liFont}>Create New Bin</Typography>
                </ListItem>
                <ListItem
                  id="addBin"
                  button
                  onClick={e => {
                    changeNewStatus(false);
                    //setPlaceLine(false);
                    changeMarkerType(pond.ObjectType.OBJECT_TYPE_BIN);
                  }}
                  className={classes.listItems}
                  style={
                    !isNew && markerType === pond.ObjectType.OBJECT_TYPE_BIN
                      ? { background: "green" }
                      : {}
                  }>
                  <ListItemIcon>
                    <BinsIcon singleBin />
                  </ListItemIcon>
                  <Typography className={classes.liFont}>Set Bin Location</Typography>
                </ListItem>
                <ListItem
                  id="addBinYard"
                  button
                  onClick={e => {
                    changeNewStatus(false);
                    //setPlaceLine(false);
                    changeMarkerType(pond.ObjectType.OBJECT_TYPE_BINYARD);
                  }}
                  className={classes.listItems}
                  style={
                    markerType === pond.ObjectType.OBJECT_TYPE_BINYARD
                      ? { background: "green" }
                      : {}
                  }>
                  <ListItemIcon>
                    <BinsIcon />
                  </ListItemIcon>
                  <Typography className={classes.liFont}>Set Bin Yard Location</Typography>
                </ListItem>
                <ListItem id="binName" className={classes.listItems}>
                  <FormControlLabel
                    label="Bin Details"
                    control={
                      <Checkbox
                        value={binMarkerDetails}
                        onChange={e => toggleBinMarkerDetails(e.target.checked)}
                      />
                    }
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item style={{ display: fieldControlDisplay }}>
              <Box>
                <Box className={classes.list}></Box>
                <List className={classes.list} style={{ marginLeft: 60 }}>
                  <ListItem
                    id="addField"
                    button
                    onClick={() => {
                      toggleEditorMode("drawPolygon");
                      toggleMeasurement(false);
                    }}
                    className={classes.listItems}
                    style={props.editorMode === "drawPolygon" ? { background: "green" } : {}}>
                    <ListItemIcon>
                      <AddFieldIcon />
                    </ListItemIcon>
                    <Typography className={classes.liFont}>Add Field/Hole</Typography>
                  </ListItem>
                  <ListItem
                    id="plotGrainBag"
                    button
                    onClick={() => {
                      toggleEditorMode("drawLine");
                      toggleMeasurement(false);
                      toggleObjectEditType(pond.ObjectType.OBJECT_TYPE_GRAIN_BAG);
                    }}
                    className={classes.listItems}
                    style={props.editorMode === "drawLine" ? { background: "green" } : {}}>
                    <ListItemIcon></ListItemIcon>
                    <Typography className={classes.liFont}>Add Grain Bag</Typography>
                  </ListItem>
                  <ListItem
                    id="editField"
                    button
                    onClick={() => {
                      toggleEditorMode("edit");
                      toggleMeasurement(false);
                    }}
                    className={classes.listItems}
                    style={props.editorMode === "edit" ? { background: "green" } : {}}>
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <Typography className={classes.liFont}>Edit Field Structures</Typography>
                  </ListItem>
                  <ListItem
                    id="deleteField"
                    button
                    onClick={() => {
                      toggleEditorMode("delete");
                      toggleMeasurement(false);
                    }}
                    className={classes.listItems}
                    style={props.editorMode === "delete" ? { background: "green" } : {}}>
                    <ListItemIcon>
                      <DeleteIcon />
                    </ListItemIcon>
                    <Typography className={classes.liFont}>Delete Field</Typography>
                  </ListItem>
                  <ListItem
                    id="fieldList"
                    button
                    onClick={goToMyFields}
                    className={classes.listItems}>
                    <ListItemIcon>
                      <FieldListIcon />
                    </ListItemIcon>
                    <Typography className={classes.liFont}>Go to My Fields</Typography>
                  </ListItem>
                  <ListItem
                    id="fieldNames"
                    button
                    onClick={() => {
                      toggleFieldTitles(!showFieldTitle);
                      setShowFieldTitles(!showFieldTitle);
                    }}
                    className={classes.listItems}>
                    <ListItemIcon>
                      {showFieldTitle ? <VisibilityOffIcon /> : <FieldNamesIcon />}
                    </ListItemIcon>
                    <Typography className={classes.liFont}>View Field Names</Typography>
                  </ListItem>
                  <ListItem
                    id="addDevice"
                    button
                    onClick={e => {
                      changeMarkerType(pond.ObjectType.OBJECT_TYPE_DEVICE);
                    }}
                    className={classes.listItems}
                    style={
                      markerType === pond.ObjectType.OBJECT_TYPE_DEVICE
                        ? { background: "green" }
                        : {}
                    }>
                    <ListItemIcon>
                      <DeviceIcon />
                    </ListItemIcon>
                    <Typography className={classes.liFont}>Set Device Location</Typography>
                  </ListItem>
                </List>
              </Box>
            </Grid>
            <Grid item style={{ display: scoutingControlDisplay }}>
              <List className={classes.list} style={{ marginLeft: 120 }}>
                <ListItem
                  id="addCustomMarker"
                  button
                  onClick={e => {
                    changeMarkerType(pond.ObjectType.OBJECT_TYPE_FIELDMARKER);
                    // if (watching) {
                    //   props.newMarker(geoPosition.longitude, geoPosition.latitude, "custom");
                    // }
                  }}
                  className={classes.listItems}
                  style={
                    markerType === pond.ObjectType.OBJECT_TYPE_FIELDMARKER
                      ? { background: "green" }
                      : {}
                  }>
                  <ListItemIcon>
                    <AddMarkerIcon />
                  </ListItemIcon>
                  <Typography className={classes.liFont}>Set Custom Marker</Typography>
                </ListItem>
                <ListItem
                  id="addMeasurementLine"
                  button
                  onClick={e => {
                    toggleEditorMode("drawLine");
                    toggleObjectEditType(pond.ObjectType.OBJECT_TYPE_UNKNOWN); //TODO: make this a measurement object type in the future
                    toggleMeasurement(true);
                  }}
                  className={classes.listItems}
                  style={props.editorMode === "drawLine" ? { background: "green" } : {}}>
                  <ListItemIcon></ListItemIcon>
                  <Typography className={classes.liFont}>Measurement Line</Typography>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
